<template>
  <div
    v-if="categories?.length"
    class="mr-7 min-w-[10%] max-w-[25%] shrink-0 overflow-hidden border-r border-grey-100 pr-7"
  >
    <h3 class="typo-body mb-5 text-grey-500">
      {{ $t("vendor_page.categories") }}
    </h3>
    <ul class="typo-body cursor-pointer text-grey-900">
      <li v-for="category in categories" :key="category.id">
        <span
          role="button"
          class="text-width-fix-medium mb-3 block truncate whitespace-nowrap text-left transition-all duration-200 hover:text-brand-600"
          :class="{
            'font-medium text-brand-600': modelValue === category.id,
          }"
          :data-title="category.name"
          @click="selectCategory(category.id)"
        >
          {{ category.name }}
        </span>
        <ul v-if="category.subcategories?.length > 0" class="ml-5">
          <li
            v-for="subcategory in category.subcategories"
            :key="subcategory.id"
          >
            <span
              role="button"
              class="text-width-fix-medium mb-3 block truncate whitespace-nowrap text-left transition-all duration-200 hover:text-brand-600"
              :class="{
                'font-medium text-brand-600': modelValue === subcategory.id,
              }"
              :data-title="subcategory.name"
              @click="selectCategory(subcategory.id)"
            >
              {{ subcategory.name }}
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
interface Props {
  modelValue: string;
  categories: OgApi.Webshop.Category[];
}

defineProps<Props>();

const emits = defineEmits(["update:modelValue"]);

function selectCategory(id: string) {
  emits("update:modelValue", id);
  const isEmbedded =
    useRouter().currentRoute.value.fullPath.includes("embedded");
  useRouter().push({
    query: { embedded: isEmbedded ? null : undefined, category: id },
  });
}
</script>
