<template>
  <BaseContent :style="backgroundImageStyle" class="bg-cover">
    <div
      class="flex flex-col-reverse items-center justify-between lg:flex-row"
      :class="[
        verticalPadding,
        alternativeLayout ? 'flex-row-reverse' : 'flex-row',
      ]"
    >
      <div class="mt-8 lg:mt-0">
        <h1 v-if="headline" class="typo-h1 lg:mr-4">
          {{ headline }}
        </h1>
        <GlobalSearch class="mt-12" />
        <span
          v-if="searchSubtext"
          class="link-container typo-body-lg mt-3 block font-medium text-grey-700"
          v-html="searchSubtext"
        />
      </div>
      <CmsImage
        :src="heroImage.url"
        :alt="heroImage.alt"
        class="lg:inline lg:max-w-[50%]"
        :max-width="864"
      />
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { useCmsImage } from "~/utils/use-cms-image";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const searchSubtext = computed(() => {
  return props.content?.search_subtext;
});

const headline = computed(() => {
  return props.content.header_text;
});

const heroImage = computed(() => {
  return {
    url: props.content.hero_image?.url,
    alt: props.content.hero_image?.alt,
  };
});

const backgroundImageStyle = computed(() => {
  return `background-image: url('${
    useCmsImage({
      src: props.content?.background_image?.url,
      params: "width=1280",
    }).value
  }')`;
});

const alternativeLayout = computed(() => {
  return props.content.alternative_layout;
});

const verticalPadding = useVerticalPadding(props);
</script>

<style scoped>
.link-container :deep(a) {
  @apply underline;
}
</style>
