<template>
  <div
    ref="dynamicText"
    class="wysiwyg-renderer"
    :class="{ 'bullet-icon-override': bulletIcon }"
    :style="`--bullet-icon: ${bulletIcon}`"
    v-html="processedContent"
  />
</template>

<script setup lang="ts">
import { Ref } from "vue";
import { useCmsImage } from "~/utils/use-cms-image";

const props = defineProps({
  content: {
    type: String,
    default: "",
  },
  bulletIcon: {
    type: String,
    default: "",
  },
});

onMounted(() => {
  addRouterNavigationToRegularLinks();
});

const dynamicText = ref<null | Ref>(null);
const bulletIcon = props?.bulletIcon ? `url('${props?.bulletIcon}')` : null;

const processedContent = computed(() => {
  return addParametersToImgSrc(props.content);
});

function addParametersToImgSrc(htmlString: string) {
  /*
    This regex matches all img tags and extracts the src attribute.
    It then replaces the src attribute with the src attribute + parameters for proper image sizing.
    At the same time it adds a srcset attribute with the same parameters, but with different quality and dpr.
  */
  return htmlString.replace(/<img [^>]*src="([^"]+)"/g, (match, src) => {
    // Check if the src already has a query string
    return match.replace(
      src,
      `${useCmsImage({ src, params: "width=800" }).value}${
        generateSrcSet(src) ? `" srcset="${generateSrcSet(src)}` : '"'
      }`,
    );
  });
}

function generateSrcSet(src: string) {
  if (src.endsWith(".svg")) {
    return;
  }

  return `
  ${
    useCmsImage({
      src,
      params: `width=800&quality=90`,
    }).value
  } 1x,
  ${
    useCmsImage({
      src,
      params: `width=800&quality=45&dpr=2`,
    }).value
  } 2x,
  ${
    useCmsImage({
      src,
      params: `width=800&quality=25&dpr=3`,
    }).value
  } 3x`;
}

function addRouterNavigationToRegularLinks() {
  if (!dynamicText.value) {
    return null;
  }

  dynamicText.value.querySelectorAll("a").forEach((a: HTMLElement) => {
    a.addEventListener("click", async (e: Event) => {
      if (!e.target) {
        return;
      }

      const eventTarget = e.target as HTMLAnchorElement;

      // external link should just be handled by browser
      if (window.location.host !== eventTarget.host) {
        return;
      }

      e.preventDefault();

      await navigateTo(eventTarget.pathname);
    });
  });
}
</script>

<style scoped>
.wysiwyg-renderer {
  @apply h-auto;
}

:deep(a) {
  @apply underline;
}

:deep(p) {
  @apply mb-3 last:mb-0;
}

:deep(p:empty) {
  @apply block;
}

:deep(p:empty:before) {
  @apply whitespace-pre;
  content: " ";
}

:deep(h2) {
  @apply typo-h2 mb-6 last:mb-0;
}

:deep(h3) {
  @apply typo-h3 mb-5.5 last:mb-0;
}

:deep(h4) {
  @apply typo-h4 mb-4.5 last:mb-0;
}

:deep(h5) {
  @apply typo-h5 mb-3.5 last:mb-0;
}

:deep(ul) {
  @apply list-disc ml-4.5;
}

:deep(ol) {
  @apply list-decimal ml-4.5;
}

.bullet-icon-override :deep(ul) {
  @apply relative list-none ml-0;
}

.bullet-icon-override :deep(ul li::before) {
  content: var(--bullet-icon);
  @apply absolute mr-3;
}

.bullet-icon-override :deep(ul li) {
  @apply mb-4 md:mb-5 last:mb-0;
}

.bullet-icon-override :deep(ul li p) {
  @apply ml-8 mb-0;
}
</style>
