<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="flex animate-redraw flex-col pb-16">
    <div v-if="vendors.length > 0 || pending" class="mb-4">
      <h2 class="typo-h5">
        <template v-if="locationsLoading">{{
          $t("request_flow.locations_loading")
        }}</template>
        <template v-else-if="!isMultiLocation">
          {{
            $t("request_flow.which_vendors_in_address_would_you_like", {
              address: locations[0]?.address,
            })
          }}
        </template>
        <template v-else>
          {{ $t("request_flow.select_vendors_near") }}
          <select
            v-model="payload.location_id"
            class="typo-body my-2 inline max-w-[100%] text-ellipsis whitespace-nowrap rounded-lg border border-grey-300 px-2.5 py-1 text-grey-700 md:my-0 md:mt-1 md:max-w-[40%]"
          >
            <option
              v-for="location in locations as OgApi.Location[]"
              :key="location.id"
              :value="location.id"
            >
              {{ location.address }}&nbsp;({{ location.zip }})
            </option>
          </select>
          {{ $t("request_flow.you_would_like_offers_from") }}
        </template>
      </h2>
      <RequestFlowComponentsSlidesComponentsVendorAmountInfo
        :amount="selectedVendorsAmount"
        :max-amount="MAX_AMOUNT_VENDORS"
        :available-vendors-amount="vendors.length"
      />
    </div>
    <template v-for="vendor in vendors" :key="vendor.id as string">
      <RequestFlowComponentsSlidesComponentsVendorItem
        :vendor="vendor"
        :is-selected="isSelected(vendor)"
        :disabled="!isSelected(vendor) && maxSelected"
        class="mb-4"
        @toggle-selection="toggleVendorSelection(vendor)"
      />
    </template>
    <div v-if="noVendors">
      <img :src="noVendorsFoundLogo" />
      <h3 class="typo-body mt-6 block font-semibold text-grey-600">
        {{
          $t("request_flow.no_vendors_deliver_to_address", {
            address: selectedLocation?.address,
          })
        }}
      </h3>
      <div class="typo-body mt-2 text-grey-500">
        {{ $t("request_flow.dont_hesitate_to_call") }}
      </div>
      <div class="typo-body mt-2 text-grey-500">
        <a href="tel:+4543991529" class="text-brand-600"
          >{{ $t("request_flow.call_us") }} +45 4399 1529</a
        >
        {{ $t("request_flow.or") }}
        <a :href="supportChatUrl" class="text-brand-600">{{
          $t("request_flow.go_to_chat_support")
        }}</a>
      </div>
      <div v-if="isMultiLocation" class="mt-16">
        <h3 class="typo-h5">
          {{
            $t(
              "request_flow.we_might_have_vendors_that_deliver_to_other_location",
            )
          }}
        </h3>
        <select
          v-model="payload.location_id"
          class="typo-body mt-4 inline max-w-[40%] text-ellipsis whitespace-nowrap rounded-lg border border-grey-300 px-2.5 py-1 text-grey-700"
        >
          <option
            v-for="location in locations as OgApi.Location[]"
            :key="location.id"
            :value="location.id"
          >
            {{ location.address }}&nbsp;({{ location.zip }})
          </option>
        </select>
      </div>
    </div>
    <RequestFlowComponentsSlidesComponentsFloatingButtons
      key="vendor-selection-floating-buttons"
      :disable-next="!hasVendorSelected"
      :next-text="nextButtonText"
      @back="emit('back')"
      @next="emit('next')"
    />
  </div>
</template>

<script setup lang="ts">
/* eslint-disable vue/no-mutating-props */
import { storeToRefs } from "pinia";
import noVendorsFoundLogo from "~/assets/no-vendors-found.svg?url";
import { useUserStore } from "~/store/UserStore";
const { t: $t } = useI18n();

const MAX_AMOUNT_VENDORS = 3;

interface Props {
  serviceId: string;
  vendorId?: string;
  payload: OgApi.TaskPayload;
}

const props = defineProps<Props>();

const emit = defineEmits(["selected", "next", "back"]);
const supportChatUrl = `${
  useRuntimeConfig().public.customerAppUrl
}conversations/support`;

const { locations, locationsLoading } = storeToRefs(useUserStore());
const selectedLocation = computed(() => {
  return locations.value.find((loc) => loc.id === props.payload.location_id);
});

const { data: vendorData, pending }: any = await useAsyncData(
  `vendors-full-${
    selectedLocation.value?.zip
      ? selectedLocation.value.zip
      : "vendors-full-all"
  }`,
  () => {
    return $fetch(
      // no need to use the auth token here, since the public endpoint serves the same
      `${useRuntimeConfig().public.apiUrl}public/services/${
        props.serviceId
      }/partners`,
      {
        params: {
          zip: selectedLocation.value?.zip,
          resource: "full", // badges
          ignore_marketplace_visibility: true,
        },
      },
    );
  },
  {
    watch: [selectedLocation],
    transform(raw: any) {
      if (!Array.isArray(raw?.data)) return raw;

      // in case there is a preselected vendor, sort it up, but only initially
      return {
        data: [...raw.data].sort((a, b) => {
          if (isSelected(a) && !isSelected(b)) return -1;
          if (!isSelected(a) && isSelected(b)) return 1;
          return 0;
        }),
      };
    },
  },
);

const vendors = computed<OgApi.VendorFull[]>(() => {
  return vendorData.value?.data || [];
});

// remove vendors from selection that are no longer available when vendor data changes (e.g. because of location change)
watchEffect(() => {
  if (!vendors.value) return;
  props.payload.partner_ids = props.payload.partner_ids.filter((id) =>
    vendors.value.find((vendor) => vendor.id === id),
  );
});

function isSelected(vendor: OgApi.Vendor) {
  return props.payload.partner_ids.includes(vendor.id);
}

function toggleVendorSelection(vendor: OgApi.Vendor) {
  if (props.payload.partner_ids.includes(vendor.id)) {
    props.payload.partner_ids = props.payload.partner_ids.filter(
      (id) => id !== vendor.id,
    );
  } else {
    if (maxSelected.value) return;
    props.payload.partner_ids = [...props.payload.partner_ids, vendor.id];
  }
  emit("selected", props.payload.partner_ids);
}

const isMultiLocation = computed(() => {
  return locations.value.length > 1;
});

const hasVendorSelected = computed(() => {
  return props.payload.partner_ids.length > 0;
});

const maxSelected = computed(() => {
  return props.payload.partner_ids.length >= MAX_AMOUNT_VENDORS;
});

const selectedVendorsAmount = computed(() => {
  return props.payload.partner_ids?.length;
});

const nextButtonText = computed(() => {
  return hasVendorSelected.value
    ? $t("request_flow.select_n_vendors", {
        count: props.payload.partner_ids.length,
      })
    : $t("request_flow.select_vendors");
});

const noVendors = computed(() => {
  return !pending.value && vendors.value?.length < 1;
});

watchEffect(() => {
  if (noVendors.value) {
    const posthog = usePosthog();
    posthog.capture("Request flow | No vendors", {
      url: window.location.href,
      serviceId: props.serviceId,
      selectedLocation: selectedLocation.value,
      hasMulti: isMultiLocation.value,
    });
  }
});
</script>

<style>
.vendor-list-enter-active,
.vendor-list-leave-active {
  transition: all 0.5s ease;
}
.vendor-list-enter-from,
.vendor-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
