<template>
  <div class="mt-1 flex flex-col gap-4">
    <div
      v-for="(item, index) in answersWoEmptyArrays as any"
      :key="index"
      class="flex flex-col gap-2"
    >
      <template v-if="!Array.isArray(item)">
        <div class="typo-body font-medium">
          {{ item || "-" }}
        </div>
      </template>
      <template v-else-if="!!item">
        <div v-for="(i, idx) in item" :key="idx" class="flex">
          <span class="flex items-center justify-center gap-2">
            <span
              class="flex h-[32px] w-[32px] shrink-0 items-center justify-center overflow-hidden rounded-md border border-grey-100 bg-grey-50"
            >
              <img
                v-if="i.sizes?.tiny"
                :src="i.sizes.tiny"
                class="max-h-[32px] w-[32px]"
                alt=""
              />
              <BaseIcon v-else icon="file" />
            </span>
            <span class="typo-caption block truncate">{{ i.name }}</span>
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  answer: [];
}

const props = defineProps<Props>();

const answersWoEmptyArrays = computed(() => {
  return props.answer.filter((a: any) => {
    if (Array.isArray(a)) {
      return a.length > 0;
    }
    return true;
  });
});
</script>
