<template>
  <div
    ref="componentRoot"
    class="group/wrapper flex"
    :class="{ compact: compact }"
    role="search"
  >
    <div
      class="group relative flex items-center rounded-lg border-solid border-grey-300 bg-transparent text-body text-grey-900 transition-all focus-within:!border-brand-600 hover:border-grey-500 group-[.compact]/wrapper:border group-[.compact]/wrapper:bg-white"
    >
      <div
        class="mr-2 flex w-auto items-center rounded-lg bg-white/75 pl-3 text-body-lg after:text-grey-200 group-[.compact]/wrapper:bg-transparent group-[.compact]/wrapper:text-body md:shrink-0"
      >
        <GlobalSearchPopover
          :results="searchResults"
          :meta="searchMeta"
          :search-term="searchTerm"
          :loading="searchLoading"
          :fullscreen="mobileView"
          @open-results="openSearchResults"
          @interface="searchMenuInterface = $event"
        >
          <!-- mobile input -->
          <div v-if="mobileView" class="flex items-center gap-4 bg-grey-50 p-4">
            <BaseIcon
              icon="close"
              class="cursor-pointer"
              :size="14"
              @click="closeSearch"
            />
            <input
              ref="mobileInput"
              v-model="searchTerm"
              required
              :placeholder="$t('search.find_anything')"
              class="h-12 w-full grow rounded-lg border border-transparent bg-white pl-2.5 outline-none placeholder:text-grey-500 focus:border-brand-600 group-[.compact]/wrapper:h-8"
              aria-label="Search field"
              @input="search"
              @click="showIfResultsExist"
              @keydown.enter="openSearchResults"
            />
          </div>
        </GlobalSearchPopover>
        <input
          v-model="searchTerm"
          required
          :placeholder="$t('search.find_anything')"
          class="peer h-12 w-full min-w-[190px] grow bg-transparent pl-2.5 outline-none placeholder:text-grey-500 group-[.compact]/wrapper:h-8"
          aria-label="Search field"
          @input="search"
          @click="showIfResultsExist"
          @keydown.enter="openSearchResults"
        />
        <BaseIcon
          class="order-first text-grey-400 peer-placeholder-shown:text-grey-600 group-[.compact]/wrapper:peer-placeholder-shown:text-brand-600 group-[.compact]/wrapper:peer-focus:text-grey-400"
          icon="search"
          :size="compact ? 14 : 12"
        />
      </div>

      <!-- We have to use !important for p-0, because the padding on the button is declared after p-0 -->
      <BaseButton
        :aria-label="$t('components.service_search.search')"
        data-action="service-search"
        class="flex h-12 w-[124px] justify-center overflow-hidden rounded-lg border-0 !p-0 text-white transition-all group-[.compact]/wrapper:h-8 group-[.compact]/wrapper:w-0 group-[.compact]/wrapper:rounded-md group-[.compact]/wrapper:rounded-l-none group-[.compact]/wrapper:delay-75 group-[.compact]/wrapper:group-focus-within:w-14"
        @click="openSearchResults"
      >
        <BaseIcon
          class="hidden group-[.compact]/wrapper:block"
          icon="search"
          :size="14"
        />
        <span class="text-body-xl-lg group-[.compact]/wrapper:hidden">{{
          $t("components.service_search.search")
        }}</span>
      </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn } from "@vueuse/core";
import GlobalSearchPopover from "~/components/global-search-popover.vue";
import { toast } from "~/utils/toast";
import { useBugsnag } from "#imports";

defineProps({
  compact: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const searchLoading = ref(false);
const searchResults = ref<null | OgApi.Search.ResultsSlim>(null);
const searchMeta = ref<null | {
  totals: { services: number; products: number; partners: number };
}>(null);
const searchTerm = ref("");
const componentRoot = ref();
const searchMenuInterface = ref<null | { show: any; hide: any }>(null);
const cancelDebounce = ref(false);

watch(
  () => useRoute().path,
  () => {
    searchTerm.value = "";
    searchMenuInterface.value?.hide();
    searchResults.value = null;
  },
);

const search = useDebounceFn(() => {
  if (cancelDebounce.value) return;
  if (searchTerm.value && searchTerm.value.length > 1) {
    searchLoading.value = true;
    searchMenuInterface.value?.show();
    useFetchApi("/public/search", {
      params: {
        search: searchTerm.value,
        per_page: 5,
        resource: "slim",
      },
    })
      .then((response: any) => {
        searchResults.value = response.data.value.data;
        searchMeta.value = response.data.value.meta;
        if (
          response.data.value.meta.totals.products ||
          response.data.value.meta.totals.partners ||
          response.data.value.meta.totals.services
        ) {
          posthog.capture("Search | Results found", {
            url: window.location.href,
            search_term: searchTerm.value,
            results: response.data.value.meta.totals,
          });
        } else {
          posthog.capture("Search | No results found", {
            url: window.location.href,
            search_term: searchTerm.value,
          });
        }
      })
      .catch((error) => {
        toast({
          text: t("search.something_went_wrong"),
          type: "error",
          duration: 5000,
          id: "global-search",
        });

        useBugsnag().notify(
          new Error(`Something went wrong while using global search`),
          (event) => {
            event.addMetadata("additional", {
              error,
              searchTerm: searchTerm.value,
            });
          },
        );
      })
      .finally(() => {
        searchLoading.value = false;
      });
  } else {
    searchResults.value = null;
    if (!mobileView.value) {
      searchMenuInterface.value?.hide();
    }
    searchLoading.value = false;
  }
}, 300);

function showIfResultsExist() {
  if (searchResults.value || mobileView.value) {
    searchMenuInterface.value?.show();
    nextTick(() => {
      if (mobileView.value) {
        mobileInput.value?.focus();
      }
    });
  }
}

function onWindowResize() {
  mobileView.value = window.innerWidth < 575;
}

const mobileInput = ref();
const mobileView = ref(false);

function closeSearch() {
  searchMenuInterface.value?.hide();
  cancelDebounce.value = true;
  setTimeout(() => {
    cancelDebounce.value = false;
  }, 600);
}

function openSearchResults() {
  if (searchTerm.value?.length < 2) {
    return;
  }
  closeSearch();
  useRouter().push({
    name: "search",
    query: {
      search: searchTerm.value,
    },
  });
}

onMounted(() => {
  // hide menu when resizing so that we don't accidentally keep it open while entering the larger breakpoints
  // where it shouldn't exist
  globalThis.addEventListener("resize", onWindowResize);
  onWindowResize();
});

onUnmounted(() => {
  globalThis.removeEventListener("resize", onWindowResize);
});
</script>
