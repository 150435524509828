<template>
  <div itemscope itemtype="https://schema.org/Organization">
    <BaseContent content-classes="!py-6 md:!py-10 lg:!py-10">
      <SingleVendorHeader :vendor="vendor" class="mb-4" />
      <SingleVendorImages
        v-if="
          (vendor?.work_images && vendor.work_images?.length) ||
          vendor.video?.info?.ready_to_stream
        "
        class="mb-10"
        :images="vendor.work_images"
        :vendor="vendor as OgApi.VendorFull"
        :vendor-name="vendor.name"
      />
      <div v-else class="h-6" />
      <div class="grid grid-cols-3 gap-14">
        <div
          class="col-span-3 lg:col-span-2"
          :class="{ 'order-last lg:order-none': REQUEST_FLOW_ACTIVATED }"
        >
          <AnchorList
            class="mb-8"
            :items="anchors as Anchor[]"
            @scroll-to="smoothScrollToElement"
          />
          <div v-if="vendor.description" ref="aboutRef" class="flex flex-col">
            <h2 class="mb-6 text-h5-lg font-bold text-grey-900">
              {{ $t("vendor_page.about") }}
            </h2>
            <ExpandableDescription
              class="!border-b-0"
              :description="vendor.description"
            />
          </div>
          <SingleVendorOverview :vendor="vendor as OgApi.VendorFull" />
          <SingleVendorProducts
            v-if="products && products.data && products.data.length"
            :vendor="vendor as OgApi.VendorFull"
            :products="products as OgApi.Webshop.ProductListResponse"
          />
          <SingleVendorServices
            v-if="vendor?.services?.length"
            ref="servicesRef"
            :vendor="vendor as OgApi.VendorFull"
          />
          <SingleVendorOperationalAreas
            v-if="vendor?.zip_ranges?.length"
            ref="operationalAreasRef"
            :vendor="vendor"
          />
          <FaqList v-if="faq.length" ref="faqRef" :items="faq" class="!py-10" />
          <SingleVendorRatings
            ref="ratingsRef"
            :vendor="vendor as OgApi.VendorFull"
            class="mb-10"
          />
          <SingleVendorReviews
            v-if="vendor?.reviews?.length"
            :vendor="vendor as OgApi.VendorFull"
          />
          <SingleVendorVerificationAndPayment
            v-if="cmsValues?.link_terms_of_payment"
            class="mt-10"
            :vendor="vendor"
            :payment-terms-link="cmsValues?.link_terms_of_payment"
          />
        </div>
        <template v-if="!REQUEST_FLOW_ACTIVATED">
          <div class="hidden lg:block">
            <WrappedContactForm
              v-if="cmsValues?.link_customer_signup"
              class="sticky top-32"
              :vendor="vendor"
              sticky
              :create-account-link="cmsValues.link_customer_signup"
            />
          </div>
        </template>
        <template v-else>
          <div class="col-span-3 block md:col-span-2 lg:col-span-1">
            <GetOfferBox
              sticky
              :services="vendor.services"
              :vendor-name="vendor.name"
              :response-time="vendor.badges?.typical_response_time"
              posthog-location="vendor_page"
            />
          </div>
        </template>
      </div>
    </BaseContent>
    <template v-if="!REQUEST_FLOW_ACTIVATED">
      <MobileWrappedContactForm
        v-if="cmsValues?.link_customer_signup"
        class="block lg:hidden"
        :vendor="vendor"
        :create-account-link="cmsValues.link_customer_signup"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import FaqList from "~/pages/components/faq-list.vue";
import WrappedContactForm from "~/pages/components/wrapped-contact-form.vue";
import MobileWrappedContactForm from "~/pages/components/mobile-wrapped-contact-form.vue";
import SingleVendorHeader from "~/pages/components/vendor/single-vendor-header.vue";
import SingleVendorImages from "~/pages/components/vendor/single-vendor-images.vue";
import SingleVendorServices from "~/pages/components/vendor/single-vendor-services.vue";
import ExpandableDescription from "~/pages/components/expandable-description.vue";
import AnchorList from "~/pages/components/anchor-list.vue";
import SingleVendorOverview from "~/pages/components/vendor/single-vendor-overview.vue";
import SingleVendorOperationalAreas from "~/pages/components/vendor/single-vendor-operational-areas.vue";
import SingleVendorRatings from "~/pages/components/vendor/single-vendor-ratings.vue";
import SingleVendorVerificationAndPayment from "~/pages/components/vendor/single-vendor-verification-and-payment.vue";
import SingleVendorReviews from "~/pages/components/vendor/single-vendor-reviews.vue";
import SingleVendorProducts from "~/pages/components/vendor/single-vendor-products.vue";
import { useCMSStore } from "~/store/CMSStore";
import GetOfferBox from "~/pages/components/get-offer-box.vue";

const $route = useRoute();
const { t } = useI18n();
const { cmsValues } = useCMSStore();
const REQUEST_FLOW_ACTIVATED =
  cmsValues?.enable_request_flow || $route.query.rf;

const products = ref();
const vendor = ref();

function fetchVendor() {
  return useFetchApi(`/public/partners/${$route.meta.vendorApiId}`, {
    query: {
      resource: "full",
      lang: globalThis.lang as string,
    },
    transform: ({ data }: { data: any }) => data,
  });
}
function fetchProducts() {
  return useFetchApi(`/public/partners/${$route.meta.vendorApiId}/products`);
}

await Promise.allSettled([fetchVendor(), fetchProducts()]).then((results) => {
  if (results[0]?.status === "fulfilled") {
    vendor.value = results[0]?.value?.data?.value;
  }
  if (results[1]?.status === "fulfilled") {
    products.value = results[1]?.value?.data?.value;
  }
});

// SEO info via CMS - this is missing the `description` which we take from the API response
const { data: seoData }: any = await useAsyncData($route.name as string, () => {
  return $fetch(`${$route.meta.apiUrl}?fields=data.seo` as string);
});

const seo: ComputedRef<SEO> = computed(() => {
  if (typeof seoData.value?.data?.seo !== "object") return;

  return {
    ...seoData.value.data.seo,
    description: vendor.value.description,
  };
});

if (seo.value) {
  useSeo(seo.value);
}

const aboutRef = ref();
const servicesRef = ref();
const operationalAreasRef = ref();
const faqRef = ref();
const ratingsRef = ref();

interface Anchor {
  ref: Ref;
  label: string;
  show: boolean;
  hidden?: boolean;
}

const anchors: ComputedRef<Anchor[]> = computed(() =>
  [
    {
      ref: aboutRef,
      label: t("vendor_page.about"),
      show: !!vendor.value.description,
    },
    {
      ref: servicesRef,
      label: t("vendor_page.services"),
      show: !!vendor.value.services?.length,
    },
    {
      ref: operationalAreasRef,
      label: t("vendor_page.operational_areas"),
      show: !!vendor.value.zip_ranges?.length,
    },
    {
      ref: faqRef,
      label: t("vendor_page.faq"),
      show: !!faq.value.length,
    },
    {
      ref: ratingsRef,
      label: t("vendor_page.ratings"),
      show: true,
    },
  ].filter((anchor: Anchor) => !anchor.hidden),
);

const faq = computed(() => {
  const { faq }: { faq: OgApi.VendorFaq } = vendor.value;
  const faqItems: FaqItem[] = [];

  for (const key in faq) {
    const cur = key as keyof OgApi.VendorFaq;

    if (!faq[cur]) continue;

    faqItems.push({
      question: t(`vendor_page.${cur}`),
      answer: faq[cur] || "",
    });
  }

  return faqItems;
});

function smoothScrollToElement(ref: Ref) {
  // need to have an OFFSET to account for the sticky header which is 64px & it
  // looked a lot nice with an extra 8px of space between the header and the element
  const OFFSET = 72;
  const element = ref?.value?.$el as HTMLElement;

  if (!element) {
    return;
  }

  const top =
    element.getBoundingClientRect().top + globalThis.pageYOffset - OFFSET;

  globalThis.scrollTo({
    top,
    behavior: "smooth",
  });
}
</script>
