<template>
  <BaseContent :style="backgroundImageStyle">
    <div class="flex flex-1 flex-col py-20 text-center">
      <span
        v-if="hasTag"
        class="mx-auto mb-2 rounded-lg px-2.5"
        :style="gradient"
      >
        <span
          class="typo-body-xl font-semibold"
          :style="`color: ${props.content.tag_text_color}`"
        >
          {{ props.content.tag_text }}
        </span>
      </span>
      <h1 class="typo-h1 mb-3">
        {{ header }}
      </h1>
      <div
        v-if="description"
        class="typo-body-2xl mx-auto max-w-prose text-grey-700"
        v-html="description"
      />
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { useCmsImage } from "~/utils/use-cms-image";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});

const header = computed((): string => {
  return props.content.header_text;
});

const description = computed((): string => {
  return props.content.description;
});

const backgroundImageStyle = computed((): string => {
  if (props.content?.background_image?.url) {
    return `background-image: url('${
      useCmsImage({
        src: props.content.background_image?.url,
        params: "width=1280",
      }).value
    }');background-size: cover;background-position: center;`;
  }
  return "";
});

const gradient = computed((): string => {
  if (
    props.content?.tag_gradient_start_color &&
    props.content?.tag_gradient_stop_color
  ) {
    return `background: linear-gradient(90deg, ${props.content.tag_gradient_start_color} 0%, ${props.content.tag_gradient_stop_color} 100%);`;
  }
  return "";
});

const hasTag = computed((): boolean => {
  return (
    props.content?.tag_text &&
    props.content?.tag_text_color &&
    props.content?.tag_gradient_start_color &&
    props.content?.tag_gradient_stop_color
  );
});
</script>
