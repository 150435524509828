<template>
  <BaseContent
    class="fixed z-50 bg-white"
    :class="`${scrollClasses} ${navigationVisibilityClasses}`"
    content-classes="!py-4"
    tag="header"
  >
    <div class="flex justify-between">
      <div class="flex items-center">
        <NuxtLink :to="`/${lang}`" class="mr-5 flex shrink-0">
          <img
            :src="ogLogo"
            alt="Officeguru logo - letter O and G intertwining into an infinity symbol"
          />
        </NuxtLink>
        <GlobalSearch compact />
      </div>
      <div class="ml-4 flex items-center gap-0 lg:flex-row-reverse lg:gap-4">
        <PageHeaderCartLink />
        <MainMenu :items="props.navigationItems as NavigationItem[]" />
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { PageHeaderProps } from "~/types/component-props";
import ogLogo from "~/assets/og-logo.svg?url";
import { useUIStore } from "~/store/UIStore";
const lang = globalThis.lang;

const props = defineProps<PageHeaderProps>();

const uiStore = useUIStore();

const scrollClasses = computed(() => {
  return hasScroll.value ? "border-b border-grey-100 shadow-soft-glow" : "";
});

const navigationVisibilityClasses = computed(() => {
  return uiStore.mainNavIsShown ? "translate-y-0" : "-translate-y-full";
});

function onWindowScroll() {
  hasScroll.value = globalThis.scrollY > 30;
}

const hasScroll = ref(false);
onMounted(() => {
  globalThis.document.addEventListener("scroll", onWindowScroll);
});

onUnmounted(() => {
  globalThis.document.removeEventListener("scroll", onWindowScroll);
});
</script>
