<template>
  <div class="flex min-h-screen w-full flex-col">
    <PageHeader />
    <BaseContent class="mt-16 flex-1">
      <RouteErrorManager :error="error" />
    </BaseContent>
    <PageFooter v-if="!isEmbedded" />
    <div data-teleport="compact-navigation" aria-hidden="true" />
  </div>
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";

interface Props {
  error: {
    url?: string;
    statusCode: number;
    statusMessage: string;
    message: string;
    description?: string;
    data?: any;
  };
}
const props = defineProps<Props>();

const { isEmbedded } = useUIStore();

// Nuxt internal error pages don't flow through the regular app.vue flow where language is usually set,
// so it has to be set here
const i18n = useI18n();
i18n.locale.value = globalThis.lang;

onMounted(() => {
  useBugsnag().notify(
    new Error(
      `General ${props.error.statusCode} error occurred, details attached`,
    ),
    (event) => {
      event.addMetadata("additional", {
        errors: props.error,
      });
    },
  );
});
</script>
