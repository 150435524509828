<template>
  <BaseContent :style="backgroundImage" class="bg-cover">
    <div class="grid grid-cols-2 gap-8">
      <div class="col-span-2 md:col-span-1">
        <div class="mb-8 flex gap-4 text-caption text-grey-500 md:mb-16">
          <NuxtLink
            :to="
              cmsValues?.link_marketplace_page
                ? cmsValues.link_marketplace_page
                : '/'
            "
          >
            {{ $t("service_page.marketplace") }}
          </NuxtLink>
          <BaseIcon icon="caret-down" :size="10" class="rotate-[270deg]" />
          <span>{{ service.title }}</span>
        </div>

        <div class="mb-12">
          <div class="mb-6 flex flex-col justify-center md:hidden">
            <CmsImage
              v-if="service.thumbnail?.url"
              class="aspect-[3/2] rounded-xl object-cover object-center shadow-totally-overcomplicated"
              itemprop="image"
              :src="service.thumbnail.url"
              :alt="service.thumbnail.alt"
              :max-width="584"
            />
          </div>
          <h1 class="mb-2 text-h3-lg font-bold text-grey-900" itemprop="name">
            {{ service.title }}
          </h1>
          <p
            class="text-body-lg text-grey-700"
            itemprop="disambiguatingDescription"
          >
            {{ service.short_description }}
          </p>
        </div>
      </div>
      <div class="hidden flex-col justify-center md:flex">
        <CmsImage
          v-if="service.thumbnail?.url"
          class="aspect-[3/2] rounded-xl object-cover object-center shadow-totally-overcomplicated"
          itemprop="image"
          :src="service.thumbnail.url"
          :alt="service.thumbnail.alt"
          :max-width="584"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useCMSStore } from "~/store/CMSStore";
import { useCmsImage } from "~/utils/use-cms-image";

const { cmsValues } = useCMSStore();
const props = defineProps<{
  service: Service;
}>();

const backgroundImage = computed(() => {
  if (!props.service?.hero_background_image?.url) {
    return "";
  }
  const [x, y] = props.service.hero_background_image?.focus
    ? props.service.hero_background_image?.focus.split("-")
    : [50, 50, 1];
  return `background-image: url('${
    useCmsImage({
      src: props.service.hero_background_image.url,
      params: "width=1280",
    }).value
  }'); background-position: ${x}% ${y}%`;
});
</script>
