<template>
  <BaseContent>
    <div
      class="flex flex-col items-center justify-between gap-10 rounded-xl px-8 py-12 md:flex-row md:gap-12 md:px-10 lg:gap-20 lg:px-14"
      :style="backgroundColor"
    >
      <div class="flex flex-col items-center">
        <img
          v-if="imageUrl"
          :src="imageUrl"
          alt=""
          class="mb-5 aspect-[1] h-[168px] min-w-[168px] bg-cover bg-center object-cover object-center lg:h-[208px] lg:min-w-[208px]"
        />
        <p
          :style="`color: ${nameColor}`"
          class="typo-body-xl mb-1 text-center font-semibold"
        >
          {{ name }}
        </p>
        <p class="typo-body-xl text-center">
          {{ jobTitle }}
        </p>
      </div>
      <div class="relative">
        <BaseIcon
          icon="quote-start"
          :size="50"
          class="absolute left-0 top-0 translate-x-[-30%] translate-y-[-30%] opacity-[.03]"
        />
        <div class="quote-content typo-body-2xl" v-html="quote" />
        <BaseIcon
          icon="quote-end"
          :size="50"
          class="absolute bottom-0 right-0 translate-x-[30%] translate-y-[30%] opacity-[.03]"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useCmsImage } from "~/utils/use-cms-image";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});

const name = computed(() => props.content?.name);
const jobTitle = computed(() => props.content?.job_title);
const quote = computed(() => props.content?.quote);
const backgroundColor = computed(() =>
  props.content?.background_color
    ? `background-color: ${props.content?.background_color}`
    : "",
);
const nameColor = computed(() => props.content?.name_color);
const imageUrl = useCmsImage({
  src: props.content?.image?.url,
  params: "width=168&height=168&fit=cover",
});
</script>

<style scoped>
.quote-content :deep(p) {
  min-height: 24px;
}
</style>
