<template>
  <component
    :is="headerComponent"
    v-if="navigationItems"
    :navigation-items="navigationItems"
  />
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";
import { PageHeaderStandard, PageHeaderEmbedded } from "#components";

async function fetchMainMenu(): Promise<NavigationItem[]> {
  const response: any = await $fetch(
    `${useRuntimeConfig().public.cmsUrl}api/navs/main_menu/tree`,
  );
  return response.data;
}
const { data: navigationItems } = useAsyncData("mainMenu", fetchMainMenu);

const isEmbedded = useUIStore().isEmbedded;
const headerComponent = computed(() => {
  return isEmbedded ? PageHeaderEmbedded : PageHeaderStandard;
});
</script>
