<template>
  <img :srcset="srcset" :src="image" :alt="alt || ''" loading="lazy" />
</template>

<script lang="ts" setup>
import { useCmsImage } from "~/utils/use-cms-image";

interface Props {
  src: string;
  alt?: string | null;
  maxWidth: number;
}

const props = defineProps<Props>();

const image = useCmsImage({
  src: props.src,
  params: `width=${props.maxWidth}`,
});

const srcset = computed(() => {
  if (props.src.endsWith(".svg")) {
    return;
  }

  return `
  ${
    useCmsImage({
      src: props.src,
      params: `width=${props.maxWidth}&quality=90`,
    }).value
  } 1x,
  ${
    useCmsImage({
      src: props.src,
      params: `width=${props.maxWidth}&quality=45&dpr=2`,
    }).value
  } 2x,
  ${
    useCmsImage({
      src: props.src,
      params: `width=${props.maxWidth}&quality=25&dpr=3`,
    }).value
  } 3x`;
});

onMounted(() => {
  validate();
});

function validate() {
  const errors: { name: string; message: string }[] = [];

  if (!props.src) {
    errors.push({
      name: "Missing src",
      message: "Image is missing src!",
    });
  }
  if (!props.maxWidth) {
    errors.push({
      name: "Missing max-width",
      message: "Image is missing max-width!",
    });
  }
  if (typeof props.alt !== "string") {
    errors.push({
      name: "Missing alt value",
      message: `Image with src ${props.src} is missing alt value!`,
    });
  }
  if (!/images(-dev)?\.officeguru/.test(props.src)) {
    errors.push({
      // eslint-disable-next-line
      name: "Missing proper host: /images(-dev)?\.officeguru/",
      message: `Image with src ${props.src} is not shipped via our image proxy!`,
    });
  }
  if (errors.length) {
    useBugsnag().notify(
      new Error(`CmsImage error(s) occurred. See additional.`),
      (event) => {
        event.addMetadata("additional", {
          errors,
        });
      },
    );
  }
}
</script>
