<template>
  <BaseContent v-if="vendor && product">
    <CartConflictModal
      :value="cartConflictModalShowing"
      @confirm="conflictConfirm"
      @cancel="conflictCancel"
    />
    <SingleVendorSubpageHeader
      v-if="vendor"
      :vendor="vendor"
      class="mb-8"
      :additional-breadcrumbs="breadcrumbs"
    />
    <div v-if="product" itemtype="https://schema.org/Product" itemscope>
      <meta itemprop="name" :content="product.name" />
      <meta itemprop="image" :content="product.media?.url" />
      <meta itemprop="description" :content="product.description" />
      <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
        <meta itemprop="availability" content="https://schema.org/InStock" />
        <meta itemprop="priceCurrency" content="DKK" />
        <meta itemprop="price" :content="product.price.toString()" />

        <div
          itemprop="seller"
          itemtype="https://schema.org/Organization"
          itemscope
        >
          <meta itemprop="name" :content="vendor.name" />
          <!-- Generate a link to the vendor -->
          <meta
            itemprop="url"
            :content="
              $config.public.rootDomain +
              $router.resolve({ name: vendorLinkName(vendor.slug) }).href
            "
          />
          <meta
            v-if="vendor.images?.logo"
            itemprop="logo"
            :content="
              (typeof vendor.images?.logo === 'object'
                ? vendor.images.logo?.url
                : vendor.images?.logo) || ''
            "
          />
        </div>
      </div>
    </div>
    <div>
      <h2 class="mb-6 max-w-[70ch] text-h5-lg font-semibold text-grey-900">
        <span
          v-if="currentProductCount(product.id)"
          class="mr-1 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-brand-600 text-body text-white"
          >{{ currentProductCount(product.id) }}</span
        >{{ product.name }}
      </h2>
    </div>
    <div class="grid grid-cols-1 gap-14 md:grid-cols-2 lg:grid-cols-3">
      <div class="col-span-1 lg:col-span-2">
        <CmsImage
          v-if="product?.media?.url"
          :src="product.media.url"
          class="max-w-full rounded-lg object-cover object-center"
          :max-width="800"
        />
        <div v-else>
          <div
            class="flex aspect-square items-center justify-center rounded-lg border border-grey-200 bg-grey-50"
          >
            <BaseIcon icon="picture" class="text-grey-300" :size="100" />
          </div>
        </div>
      </div>
      <div class="col-span-1 mt-[-7px]">
        <p class="text-body text-grey-500">
          {{ $t("product_page.price_excl_vat") }}
        </p>
        <p class="mb-4 text-h5-lg font-semibold text-grey-900">
          {{ product.price }} DKK
        </p>

        <WysiwygRenderer
          v-if="product.description"
          class="text-body text-grey-900"
          :content="product.description"
        />

        <div class="flex gap-4 border-b border-grey-200 py-8">
          <SelectDropdown v-model="itemCount" :options="itemOptions" />
          <BaseButton
            class="flex-1"
            :disabled="addProductLoading"
            @click="addProductToCart"
            ><BaseIcon icon="cart" :size="14" class="mr-1" />
            {{ $t("product_page.add_to_cart") }}</BaseButton
          >
        </div>
      </div>
    </div>
  </BaseContent>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { toast } from "~/utils/toast";
import SingleVendorSubpageHeader from "~/pages/components/vendor/single-vendor-subpage-header.vue";
import { useSeo } from "~/utils/use-seo";
import { useCartStore } from "~/store/CartStore";
import CartConflictModal from "~/pages/components/cart/cart-conflict-modal.vue";
import { vendorLinkName } from "~/utils/linking";
import { track } from "~/utils/tracking/tracking";

const cartStore = useCartStore();
const $route = useRoute();
const $router = useRouter();
const $config = useRuntimeConfig();
const { t } = useI18n();
await Promise.allSettled([]);
const product = ref<OgApi.Webshop.Product>();
const vendor = ref<OgApi.VendorFull>();
const { cartLoading, cartMounted } = storeToRefs(cartStore);
function getProduct() {
  return useFetchApi(
    `/public/partners/${$route.meta.partnerApiId}/products/${$route.meta.productApiId}`,
    {
      transform: ({ data }: { data: any }) => data,
    },
  );
}
function getVendor() {
  return useFetchApi(`/public/partners/${$route.meta.partnerApiId}`, {
    query: {
      resource: "full",
    },
    transform: ({ data }: { data: any }) => data,
  });
}

await Promise.allSettled([getProduct(), getVendor()]).then((results) => {
  if (results[0]?.status === "fulfilled") {
    product.value = results[0]?.value?.data?.value;
    track({
      event: "view_product",
      metadata: {
        product_id: product.value?.id || "",
        product_name: product.value?.name || "",
      },
    });
  }
  if (results[1]?.status === "fulfilled") {
    vendor.value = results[1]?.value?.data?.value;
  }
});

const breadcrumbs = computed(() => {
  if (!vendor.value || !product.value) {
    return [];
  }
  return [
    {
      label: t("vendor_page.products"),
      to: { name: `vendors-${vendor.value.slug}-products` },
    },
    { label: product.value.name },
  ];
});

// Item selection
const itemCount = ref(1);
const itemOptions = computed(() => {
  return Array.from({ length: 99 }, (_, i) => ({
    label: (i + 1).toString(),
    value: i + 1,
  }));
});

const cartConflictModalShowing = ref(false);

// Cart
const addProductLoading = ref(false);
function addProductToCart() {
  if (
    cartStore.cart?.partner &&
    cartStore.cart?.partner?.id !== vendor.value?.id
  ) {
    cartConflictModalShowing.value = true;
    return;
  }

  addProductLoading.value = true;
  cartStore
    .addProductToCart($route.meta.productApiId as string, itemCount.value)
    .then(() => {
      track({
        posthogEvent: {
          name: "Product added to cart",
          properties: {
            url: window.location.href,
          },
        },
        event: "add_to_cart",
        metadata: {
          type: "product",
          products: [
            {
              product_id: product.value?.id || "",
              price: product.value?.price || 0,
              quantity: itemCount.value,
              name: product.value?.name || "",
            },
          ],
        },
      });
      toast({
        text: t("product_page.added_x_y_to_cart", {
          name: product.value?.name,
          count: itemCount.value,
        }),
        link: {
          label: t("cart.go_to_cart"),
          to: { name: "cart" },
        },
      });
    })
    .finally(() => {
      addProductLoading.value = false;
    });
}

async function conflictConfirm() {
  cartConflictModalShowing.value = false;
  await cartStore.deleteCart();
  addProductToCart();
}

function conflictCancel() {
  cartConflictModalShowing.value = false;
}

function currentProductCount(id: string) {
  return cartStore.productCountMap[id] || 0;
}

// SEO
const { data: seoData }: any = await useAsyncData(
  $route.name as string,
  () => {
    return $fetch(`${$route.meta.apiUrl}?fields=data.seo` as string);
  },
  { lazy: true },
);

const seo: ComputedRef<SEO> = computed(() => {
  if (typeof seoData.value?.data?.seo !== "object") return;

  return {
    ...seoData.value.data.seo,
    description: vendor.value ? vendor.value.description : "",
  };
});

if (seo.value) {
  useSeo(seo.value);
}

let addedThisSession = false;
watch([cartLoading, cartMounted], async ([loading, mounted]) => {
  if (
    !addedThisSession &&
    !loading &&
    mounted &&
    Object.hasOwn($route?.query, "add")
  ) {
    addedThisSession = true;
    addProductToCart();
    await $router.replace({ query: { ...$route.query, add: undefined } });
  }
});
</script>
