import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  vueApp.use(Vue3Toasity, {
    position: "top-center",
    containerClassName: "mt-14",
  } as ToastContainerOptions);
});
