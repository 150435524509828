export function useConsent() {
  if (process.server) return;

  const nuxtApp = useNuxtApp();
  // if (import.meta.env.MODE === "development") return;
  const consentInterval = setInterval(() => {
    if (document.cookie.includes("zaraz-consent")) {
      if (window.POSTHOG_ENABLED) {
        nuxtApp.$posthog.init();
      }

      clearInterval(consentInterval);
    }
  }, 1000);
}
