<template>
  <BaseContent
    :class="`${backgroundColor} ${verticalPadding}`"
    :style="`background-color: ${backgroundColor}`"
  >
    <h2 class="typo-h2 text-center">
      {{ header }}
    </h2>
    <div
      class="-mb-6 flex flex-wrap items-center justify-center md:flex-nowrap md:justify-between"
    >
      <div
        v-for="logo in content.logos"
        :key="logo.id"
        class="flex w-1/3 justify-center px-3 pb-6 first:pl-0 md:w-auto md:px-4 md:last:pr-0 lg:px-6 max-md:[&:nth-child(3n)]:pr-0"
      >
        <CmsImage
          alt="company logo"
          :src="logo.image.url"
          class="max-h-11"
          :max-width="250"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  raw: {
    type: Object,
    required: true,
  },
});

const header = computed((): string => {
  return props.content.header_text;
});

const backgroundColor = computed((): string => {
  return props.content.background_color
    ? props.content.background_color
    : "#ffffff";
});

const verticalPadding = useVerticalPadding(props);
</script>
