<template>
  <CmsImage
    class="col-span-1 row-span-1 aspect-[1.7] h-full w-full object-cover object-center"
    :src="props.image.url"
    :alt="altString"
    aria-label="View image"
    role="button"
    tabindex="0"
    :max-width="600"
    @click="action"
    @keydown.enter="action"
  />
</template>

<script lang="ts" setup>
const props = defineProps<{
  image: { url: string; description?: string };
  action: any;
  altPrefix?: string;
}>();

const altString = computed(() => {
  if (props.altPrefix) return `${props.altPrefix} - ${props.image.description}`;
  return props.image.description;
});
</script>
