import cookies from "js-cookie";
import { ENVIRONMENTS } from "@officeguru/webapp-shared/src/helpers/environment";
import { toast, removeToast } from "~/utils/toast";
import { useUIStore } from "~/store/UIStore";
const TOAST_ID_LOGGED_IN = "logged-in";
const TOAST_ID_LOGGED_OUT = "logged-out";

export const useAuthStore = defineStore("AuthStore", () => {
  const config = useRuntimeConfig();
  const authToken = ref("initial");
  const loginLoading = ref(false);
  const loginErrors = ref({});

  function getCookieEnvironmentPrefix(): void | string {
    const APP_ENV = useRuntimeConfig().public.appEnv;
    const prefixMap = {
      local: ENVIRONMENTS.LOCAL,
      development: ENVIRONMENTS.DEVELOPMENT,
      production: ENVIRONMENTS.PRODUCTION,
    };
    const prefix = prefixMap[APP_ENV as keyof typeof prefixMap];

    if (!prefix) throw new Error(`Unknown APP_ENV: ${APP_ENV}`);

    return prefix;
  }

  const COOKIE_NAME = `officeguru_api_token_${getCookieEnvironmentPrefix()}`;

  updateAuthTokenWithCookieValue();
  setInterval(() => {
    updateAuthTokenWithCookieValue();
  }, 500);

  let interval = null as any;
  watch(
    authToken,
    (newValue, oldValue) => {
      if (newValue === "initial" && !oldValue) return;
      if (!newValue && oldValue === "initial") return;

      if (oldValue && !newValue) {
        removeToast(TOAST_ID_LOGGED_IN);
        toast({ text: "Logged out!", type: "error", id: TOAST_ID_LOGGED_OUT });
        setTimeout(() => {
          const { isEmbedded } = useUIStore();
          if (isEmbedded) {
            globalThis.location.href = config.public.customerAppUrl;
          }
        }, 2000);
        return clearInterval(interval);
      }

      if (newValue && !oldValue) {
        removeToast(TOAST_ID_LOGGED_OUT);
        toast({ text: "Logged in!", id: TOAST_ID_LOGGED_IN });
      }

      interval = setInterval(() => {
        // auth check
        globalThis
          .$fetch(`${config.public.apiUrl}authorized`, {
            method: "HEAD",
            headers: {
              Authorization: `Bearer ${useAuthStore().token}`,
              Accept: "application/json",
            },
          })
          .catch((e) => {
            if (e.status === 401) {
              const { isEmbedded } = useUIStore();
              if (isEmbedded) {
                globalThis.location.href = config.public.customerAppUrl;
              }
              return clear();
            }
            throw e;
          });
      }, 30000);
    },
    { immediate: false },
  );

  function updateAuthTokenWithCookieValue() {
    if (!process.client) return "";
    authToken.value = cookies.get(COOKIE_NAME) || "";
  }

  function clear() {
    const rootDomain = config.public.rootDomain;

    cookies.remove(COOKIE_NAME, {
      path: "/",
      domain: `.${rootDomain}`,
      sameSite: "strict",
    });
  }

  function set(token: string) {
    const rootDomain = config.public.rootDomain;

    cookies.set(COOKIE_NAME, token, {
      path: "/",
      domain: `.${rootDomain}`,
      expires: 400, // 400 days, maximum possible
      sameSite: "strict",
    });
  }

  async function login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    loginLoading.value = true;
    loginErrors.value = {};

    try {
      const { data }: any = await globalThis.$fetch(
        `${useRuntimeConfig().public.apiUrl}customer/login`,
        {
          method: "POST",
          body: {
            email,
            password,
          },
          headers: {
            Accept: "application/json",
          },
        },
      );
      set(data.token);
      loginErrors.value = {};
    } catch (e: any) {
      if (
        e.data?.errors as {
          [key: string]: Array<{
            identifier: string;
            message: string;
          }>;
        }
      ) {
        loginErrors.value = e.data.errors;
      } else {
        useBugsnag().notify(e);
      }
    } finally {
      loginLoading.value = false;
    }
  }

  const isLoggedIn = computed(
    () => authToken.value !== "initial" && !!authToken.value,
  );

  return {
    token: authToken,
    isLoggedIn,
    setToken: set,
    clear,
    login,
    loginLoading,
    loginErrors,
  };
});
