<template>
  <div class="mt-4 flex flex-col">
    <div
      class="rounded-lg border border-dashed border-grey-300"
      @dragenter.prevent="isDragging = true"
      @dragleave.prevent="isDragging = false"
      @dragover.prevent="isDragging = true"
      @drop.prevent="isDragging = false"
    >
      <input
        ref="fileUpload"
        type="file"
        multiple
        class="hidden"
        @change="onSelect"
      />
      <div
        class="typo-body flex items-center p-4 text-grey-900"
        @drop="onDrop"
        @click="openFileUpload"
      >
        <template v-if="!isDragging">
          <BaseIcon icon="upload" class="mr-2.5" :size="14" />
          {{ $t("request_flow.drag_file_here") }}
        </template>
        <template v-else>
          <BaseIcon icon="checkmark" class="mr-2.5" :size="14" />
          {{ $t("request_flow.now_drop") }}
        </template>
      </div>
    </div>
    <div v-if="fileObjects.length" class="mt-4">
      <ul class="flex flex-col gap-4">
        <li
          v-for="fileObject in fileObjects"
          :key="fileObject.id"
          class="flex items-center gap-4"
        >
          <span
            class="flex h-[32px] w-[32px] shrink-0 items-center justify-center overflow-hidden rounded-md border border-grey-100 bg-grey-50"
          >
            <img
              v-if="fileObject.response?.sizes?.tiny"
              :src="fileObject.response?.sizes?.tiny"
              class="max-h-[32px] w-[32px]"
            />
            <BaseIcon v-else-if="!fileObject.error" icon="file"></BaseIcon>
            <BaseIcon v-else icon="close" class="text-semantic-negative-700" />
          </span>
          <span
            class="typo-caption block truncate"
            :class="{ 'text-semantic-negative-700': fileObject.error }"
            >{{ fileObject.file.name
            }}<span v-if="fileObject.error"> [Upload failed!]</span></span
          >
          <span v-if="fileObject.uploading" class="ml-auto flex text-grey-500"
            ><BaseIcon icon="loading" class="animate-spin"
          /></span>
          <span
            v-if="fileObject.uploaded || fileObject.error"
            class="ml-auto flex text-grey-500"
            :class="{ 'text-semantic-negative-700': fileObject.error }"
            @click="removeFileObject(fileObject)"
            ><BaseIcon icon="close"
          /></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable vue/no-mutating-props */
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/AuthStore";
const { isLoggedIn } = storeToRefs(useAuthStore());

const props = defineProps<{
  question: OgApi.ServiceQuestionWithAnswer;
}>();

const emit = defineEmits(["update"]);

onMounted(() => {
  props.question.answer = [];
});

interface FileObject {
  uploading: boolean;
  uploaded: boolean;
  progress: number;
  id: string;
  file: File;
  response?: OgApi.MediaUploadResponse;
  error?: any;
}

const isDragging = ref(false);

const fileObjects: Ref<FileObject[]> = ref([]);
const fileUpload: Ref<null | HTMLInputElement> = ref(null);

function openFileUpload() {
  if (fileUpload.value) fileUpload.value.click();
}

function onSelect(e: any) {
  const newFiles = [
    ...Array.from(e.target.files as File[]).map((file) => {
      return {
        file,
        uploading: false,
        uploaded: false,
        progress: 0,
        id: "" + Math.random(),
      };
    }),
  ];

  fileObjects.value = [...fileObjects.value, ...newFiles];
  if (fileUpload.value) fileUpload.value.value = "";
  handleUpload();
}

function onDrop(e: any) {
  const newFiles = [
    ...Array.from(e.dataTransfer.files as File[]).map((file) => {
      return {
        file,
        uploading: false,
        uploaded: false,
        progress: 0,
        id: "" + Math.random(),
      };
    }),
  ];

  fileObjects.value = [...fileObjects.value, ...newFiles];

  handleUpload();
}

function handleUpload() {
  const toBeUploadedIds = fileObjects.value
    .filter((fileObject) => {
      return !fileObject.uploading && !fileObject.uploaded;
    })
    .map((fileObject) => fileObject.id);

  if (!toBeUploadedIds.length) return;

  fileObjects.value.forEach((fileObject) => {
    if (!toBeUploadedIds.includes(fileObject.id)) return;
    upload(fileObject);
  });
}

function removeFileObject(fileObject: FileObject) {
  fileObjects.value = fileObjects.value.filter((fo) => fo.id !== fileObject.id);
}

async function upload(fileObject: FileObject) {
  fileObject.uploading = true;

  const params = new FormData();
  params.append("file", fileObject.file);

  try {
    // @ts-ignore
    const { data }: { data: OgApi.MediaUploadResponse } = await $fetch(
      `${useRuntimeConfig().public.apiUrl}${
        isLoggedIn.value ? "medias" : "public/medias"
      }`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          ...(isLoggedIn.value
            ? { Authorization: `Bearer ${useAuthStore().token}` }
            : {}),
          ...(!isLoggedIn.value
            ? {
                "X-Recaptcha-Token":
                  await useNuxtApp().$recaptcha.execute("upload_media"),
                "X-Recaptcha-Action": "upload_media",
              }
            : {}),
        },
        body: params,
      },
    );
    fileObject.response = data;
    fileObject.uploaded = true;
  } catch (e) {
    fileObject.error = e;
    fileObject.uploaded = false;
  } finally {
    fileObject.uploading = false;
  }
}

// sync the uploaded files with the question answer
watchEffect(() => {
  props.question.answer = fileObjects.value
    .map((fileObject) => fileObject.response)
    .filter((response) => !!response);

  emit("update", {
    question: props.question,
    answer: props.question.answer,
  });
});
</script>
