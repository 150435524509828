<template>
  <BaseContent content-classes="!pt-0">
    <div
      class="border-b border-b-grey-200 pb-10"
      itemprop="itemReviewed"
      itemscope
      itemtype="https://schema.org/Product"
    >
      <div
        v-if="aggregate"
        class="hidden"
        itemprop="aggregateRating"
        itemtype="https://schema.org/AggregateRating"
        itemscope
      >
        <meta itemprop="reviewCount" :content="aggregate.count" />
        <meta itemprop="ratingValue" :content="aggregate.value" />
      </div>
      <h2 class="mb-4 text-h5-lg font-bold text-grey-900" itemprop="name">
        {{
          $t("service_page.reviews_of_service", {
            service: service.title.toLowerCase(),
          })
        }}
      </h2>
      <div class="grid gap-8 md:grid-cols-3">
        <div
          v-for="(review, index) in reviews"
          :key="index"
          itemprop="review"
          itemscope
          itemtype="https://schema.org/Review"
        >
          <div
            class="mb-4 flex items-center gap-2"
            itemprop="author"
            itemscope
            itemtype="https://schema.org/Person"
          >
            <div
              class="flex h-[40px] w-[40px] items-center justify-center rounded-full border border-grey-200 bg-grey-50 bg-cover bg-center text-body text-semantic-info-800"
              :style="
                authorImage(review)
                  ? `background-image: url('${authorImage(review)}')`
                  : ''
              "
            >
              <span v-if="!authorImage(review)">{{
                initials(review.author_name)
              }}</span>
              <meta
                v-if="authorImage(review)"
                itemprop="image"
                :content="authorImage(review)"
              />
            </div>
            <span
              class="text-body font-semibold text-grey-900"
              itemprop="name"
              >{{ review.author_name }}</span
            >
          </div>
          <div
            itemprop="reviewRating"
            itemscope
            itemtype="https://schema.org/Rating"
          >
            <RatingStars :rating="review.rating" class="mb-4" />
            <meta itemprop="ratingValue" :content="review.rating" />
            <meta itemprop="bestRating" content="5" />
          </div>
          <WysiwygRenderer
            itemprop="reviewBody"
            :content="review.text"
            class="text-body text-grey-700"
          />
          <span class="mt-4 text-body text-grey-500"
            >{{ $t("service_page.delivered_by") }}
            <NuxtLink
              class="cursor-pointer text-brand-600"
              :to="review.vendor?.url"
              >{{ review.vendor?.title }}</NuxtLink
            >
          </span>
        </div>
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { initials } from "~/utils/initials";
import { useCmsImage } from "~/utils/use-cms-image";

const props = defineProps({
  service: {
    type: Object as PropType<Service>,
    required: true,
  },
});

const reviews = computed(() => {
  return props.service?.reviews;
});

function authorImage(review: any) {
  return useCmsImage({
    src: review?.author_image?.url,
    params: "width=40&height=40&fit=cover",
  }).value;
}

const aggregate = computed(() => {
  if (!reviews.value?.length) return false;

  return {
    count: reviews.value.length.toString(),
    value: (
      reviews.value.reduce(
        (acc: number, review: ServiceReview) => acc + review.rating,
        0,
      ) / reviews.value.length
    ).toFixed(1),
  };
});
</script>
