<template>
  <BaseContent :class="`${verticalPadding}`">
    <div class="flex w-full flex-col overflow-hidden lg:flex-row">
      <!-- image -->
      <div class="relative flex w-full overflow-hidden rounded-xl lg:w-7/12">
        <CmsImage :src="image.url" :alt="image.alt" :max-width="911" />
        <div class="absolute flex flex-wrap p-8 md:p-6 md:px-10 md:py-14">
          <div
            v-if="hasTag"
            class="mb-4 rounded-lg px-2.5 md:mb-5"
            :style="gradient"
          >
            <span
              class="typo-body-xl font-semibold"
              :style="`color: ${props.content.tag_text_color}`"
            >
              {{ props.content.tag_text }}
            </span>
          </div>
          <h3
            class="typo-h3"
            :style="`color: ${props.content.text_field_color}`"
          >
            {{ props.content.text_field }}
          </h3>
        </div>
      </div>
      <!-- services -->
      <div class="flex flex-col lg:w-5/12 lg:justify-center lg:pl-12">
        <div
          v-for="service in servicesExtended"
          :key="service.id"
          class="mt-8 flex flex-row lg:first:mt-0"
        >
          <div
            class="mr-4 min-w-[0.375rem] overflow-hidden rounded-2xl bg-brand-100"
          >
            <div
              class="w-full bg-brand-200"
              :style="{ height: service.active ? progress : '0%' }"
            ></div>
          </div>
          <!-- active -->
          <NuxtLink
            v-if="service.active"
            :to="service.url"
            class="group"
            @mouseenter="pauseCounter"
            @mouseleave="resumeCounter"
          >
            <h3
              class="typo-h4 flex w-full items-center text-brand-600 group-hover:underline"
            >
              {{ service.title }}
              <BaseIcon class="ml-2 mt-0.5" icon="arrow-right-v2" :size="24" />
            </h3>
            <span class="typo-body-xl mt-2 flex w-full">{{
              service.excerpt
            }}</span>
          </NuxtLink>
          <!-- inactive -->
          <button v-else @click="activateService(service)">
            <h3
              class="typo-h4 flex w-full select-none text-grey-500 hover:text-brand-600"
            >
              {{ service.title }}
            </h3>
          </button>
        </div>
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useInterval } from "@vueuse/core";
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
const props = defineProps<{
  content: {
    text_field: string;
    text_field_color: string;
    tag_text: string;
    tag_text_color: string;
    image: {
      url: string;
      alt: string;
    };
    services: Service[];
    auto_switch_interval: number;
    tag_gradient: string;
    tag_gradient_start_color: string;
    tag_gradient_stop_color: string;
  };
  raw: any;
}>();

const SERVICE_SWITCH_INTERVAL = props.content.auto_switch_interval || 5000;
const COUNT_INTERVAL = 100;
const {
  counter,
  reset: resetCounter,
  pause: pauseCounter,
  resume: resumeCounter,
} = useInterval(COUNT_INTERVAL, {
  controls: true,
});
watch(counter, (value) => {
  if (value >= SERVICE_SWITCH_INTERVAL / COUNT_INTERVAL) {
    const activeIndex = servicesExtended.value.findIndex(
      ({ active }) => active,
    );
    const nextService =
      servicesExtended.value[activeIndex + 1] || servicesExtended.value[0];
    activateService(nextService);
  }
});
const progress = computed(() => {
  return `${
    (counter.value / (SERVICE_SWITCH_INTERVAL / COUNT_INTERVAL)) * 100
  }%`;
});
interface ExtendedService extends Service {
  active: boolean;
}
const servicesExtended = computed(() =>
  props.content.services.map((service: Service, index: number) => {
    return reactive({
      ...service,
      active: index === 0,
    });
  }),
);
function activateService(service: Service) {
  resetCounter();
  servicesExtended.value.forEach((serviceExtended: ExtendedService) => {
    serviceExtended.active = false;
    serviceExtended.active = serviceExtended.id === service.id;
  });
}

const gradient = computed((): string => {
  if (
    props.content?.tag_gradient_start_color &&
    props.content?.tag_gradient_stop_color
  ) {
    return `background: linear-gradient(90deg, ${props.content.tag_gradient_start_color} 0%, ${props.content.tag_gradient_stop_color} 100%);`;
  }
  return "";
});

const image = computed(() => props.content.image);
const hasTag: ComputedRef<boolean> = computed(() => {
  return (
    !!props.content?.tag_text &&
    !!props.content?.tag_text_color &&
    !!props.content?.tag_gradient_start_color &&
    !!props.content?.tag_gradient_stop_color
  );
});

const verticalPadding = useVerticalPadding(props);
</script>
