<template>
  <BaseContent>
    <SingleVendorSubpageHeader
      v-if="vendorData"
      :vendor="vendorData"
      class="mb-8"
      :additional-breadcrumbs="breadcrumbs"
    />
    <div v-if="showCategoryDropdown && !categoriesError" class="mb-12">
      <h2 class="mb-4 text-h5-lg font-bold text-grey-900">
        {{ $t("vendor_page.products") }}
      </h2>
      <SelectDropdown
        v-if="categoriesOptions"
        v-model="selectedCategory"
        :options="categoriesOptions"
      />
    </div>
    <div class="flex flex-row">
      <SingleVendorCategoryList
        v-if="!showCategoryDropdown && categoriesData && !categoriesError"
        v-model="selectedCategory"
        :categories="categoriesData"
      />
      <template v-if="productsError">
        <div class="typo-body m-auto flex self-center text-grey-700">
          <span>{{ $t("vendor_page.loading_of_products_failed") }}</span
          >&nbsp;
          <span role="button" @click="resetFilters">
            {{ $t("vendor_page.click_here_to_refresh") }}
          </span>
        </div>
      </template>
      <template v-else>
        <SingleVendorProductsTable
          v-if="vendorData && productsData"
          :products="productsData"
          :vendor="vendorData"
          :show-header="!showCategoryDropdown"
          class="flex-1"
          :class="{ 'pointer-events-none opacity-30': productsLoading }"
          grid
        />
      </template>
    </div>
    <BasePagination
      v-if="productsMeta"
      :info="productsMeta"
      :translated-resource-name="$t('vendor_page.products')"
      @click="page = $event"
    />
  </BaseContent>
</template>
<script setup lang="ts">
import { useWindowSize } from "@vueuse/core";
import SingleVendorSubpageHeader from "~/pages/components/vendor/single-vendor-subpage-header.vue";
import SingleVendorProductsTable from "~/pages/components/vendor/single-vendor-products-table.vue";
import SingleVendorCategoryList from "~/pages/components/vendor/single-vendor-category-list.vue";
import { useFetchApi } from "~/utils/use-fetch-api";

const $route = useRoute();
const { t } = useI18n();

const page = ref(1);
const selectedCategory = ref(
  (useRouter().currentRoute.value.query?.category as string) || "",
);

watch([selectedCategory, page], ([categoryNew], [categoryOld]) => {
  if (productsFetch.status === "rejected") return;

  const isCategoryChange = categoryNew !== categoryOld;

  if (isCategoryChange) {
    // set page to 1 and stop execution, as setting page will trigger this watcher again and
    // refresh the results w. the updated category, but page 1 instead of an old page that could be
    // out of bounds
    page.value = 1;
    return;
  }

  productsFetch.value?.refresh();
});

const [vendorFetch, productsFetch, categoriesFetch] = await Promise.allSettled([
  useFetchApi(`/public/partners/${$route.meta.vendorApiId}`, {
    query: {
      resource: "full",
    },
  }),
  useFetchApi(`/public/partners/${$route.meta.vendorApiId}/products`, {
    query: {
      page,
      category_id: selectedCategory,
    },
  }),
  useFetchApi(
    `/public/partners/${$route.meta.vendorApiId}/product-categories`,
    {
      query: {
        per_page: 9999,
      },
    },
  ),
]);

// PRODUCTS
const productsData = computed((): OgApi.Webshop.Product[] | null => {
  if (productsFetch.status === "rejected") return null;

  // @ts-ignore-next-line
  return productsFetch.value?.data?.value?.data || null;
});

const productsMeta = computed((): OgApi.ListResponseMeta | null => {
  if (productsFetch.status === "rejected") return null;

  // @ts-ignore-next-line
  return productsFetch.value?.data?.value?.meta || null;
});

const productsLoading = computed(() => {
  if (productsFetch.status === "rejected") return false;

  return productsFetch.value?.pending?.value;
});

const productsError = computed(() => {
  if (productsFetch.status === "rejected") return true;

  return productsFetch.value?.error?.value;
});

// VENDOR
const vendorData = computed((): OgApi.VendorFull | null => {
  if (vendorFetch.status === "rejected") return null;

  // @ts-ignore-next-line
  return vendorFetch.value?.data?.value?.data || null;
});

// CATEGORIES
const categoriesError = computed(() => {
  if (categoriesFetch.status === "rejected") return true;

  return categoriesFetch.value?.error?.value;
});

const categoriesData = computed((): OgApi.Webshop.Category[] | null => {
  if (categoriesFetch.status === "rejected") return null;

  const categories: OgApi.Webshop.Category[] =
    // @ts-ignore-next-line
    categoriesFetch.value?.data?.value?.data || [];

  const allCategory: OgApi.Webshop.Category = {
    id: "",
    name: t("vendor_page.all_products"),
    subcategories: [],
  };

  return [allCategory, ...categories];
});

const categoriesOptions = computed(
  (): { label: string; value: string; isChild?: boolean }[] | null => {
    if (categoriesFetch.status === "rejected" || !categoriesData.value)
      return null;

    return categoriesData.value.flatMap((category) => [
      { label: category.name, value: category.id },
      ...category.subcategories.map((subcategory) => ({
        label: `${subcategory.name}`,
        value: subcategory.id,
        isChild: true,
      })),
    ]);
  },
);

const showCategoryDropdown = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 960;
});

// OTHERS
const breadcrumbs = computed(() => {
  return [{ label: t("vendor_page.products") }];
});

function resetFilters() {
  selectedCategory.value = "";
  page.value = 1;
}
</script>
