import { useDevicePixelRatio } from "@vueuse/core";

export function useCmsImage({ src, params }: { src: string; params: string }) {
  if (!src) {
    return computed(() => "");
  }

  if (src.toLowerCase().endsWith(".svg")) {
    return computed(() => src);
  }

  if (!params.includes("width=")) {
    // eslint-disable-next-line no-console
    console.error(
      "Params is mandatory, at least set a `width` (the maximum you ever need).",
    );
    return computed(() => "");
  }

  if (!params.includes("dpr=")) {
    const { pixelRatio } = useDevicePixelRatio();
    const dpr = process.server ? 2 : pixelRatio.value;

    params = `dpr=${dpr}&${params}`;
  }

  return computed(() => {
    return encodeURI(`${src}?${params}`);
  });
}
