import { PageRenderer } from "#components";
import SingleService from "~/pages/single-service.vue";
import SingleVendor from "~/pages/single-vendor.vue";
import SingleBlog from "~/pages/single-blog.vue";
import SingleProduct from "~/pages/components/single-product.vue";
import SingleVendorProductsList from "~/pages/single-vendor-products-list.vue";
import { linkName } from "~/utils/linking";
const handleMap: { [key: string]: Component } = {
  services: SingleService,
  vendors: SingleVendor,
  blogs: SingleBlog,
  products: SingleProduct,
};

export default defineNuxtPlugin(async () => {
  const router = useRouter();

  (await getRoutesFromCms()).forEach((o: CmsCollection) => {
    const isService = o.page.collection.handle === "services";
    const isVendor = o.page.collection.handle === "vendors";
    const isProduct = o.page.collection.handle === "products";

    const route = {
      name: generateName(o.page),
      path: o.page.url,
      component: handleMap[o.page.collection.handle] || PageRenderer,
      meta: {
        apiUrl: o.page.api_url,
        ...(isService
          ? { serviceApiId: o.page.api_id, serviceSlug: o.page.slug }
          : {}),
        ...(isVendor
          ? { vendorApiId: o.page.api_id, vendorSlug: o.page.slug }
          : {}),
        ...(isProduct
          ? {
              productApiId: o.page.api_id,
              productSlug: o.page.slug,
              partnerApiId: o.page.partner_api_id,
            }
          : {}),
      },
    };
    router.addRoute("routes", route);

    if (isVendor) {
      const productList = {
        name: `vendors-${o.page.slug}-products`,
        path: `${o.page.url}/${
          globalThis.lang === "en" ? "products" : "produkter"
        }`,
        component: SingleVendorProductsList,
        meta: {
          apiUrl: o.page.api_url,
          vendorApiId: o.page.api_id,
          vendorSlug: o.page.slug,
        },
      };
      router.addRoute("routes", productList);
    }
  });

  router.addRoute("routes", {
    name: "index",
    path: "/",
    redirect: `/${globalThis.lang}`,
  });

  router.addRoute("routes", {
    name: "search",
    path: globalThis.lang === "en" ? "/en/search" : "/da/soeg",
    component: () => import("~/pages/single-search.vue"),
  });

  router.addRoute("routes", {
    name: "cart",
    path: globalThis.lang === "en" ? "/en/cart" : "/da/kurv",
    component: () => import("~/pages/single-cart.vue"),
  });

  router.addRoute("routes", {
    name: "checkout",
    path: globalThis.lang === "en" ? "/en/checkout" : "/da/kasse",
    component: () => import("~/pages/single-checkout.vue"),
  });
});

async function getRoutesFromCms() {
  const config = useRuntimeConfig();

  let collections = ["pages", "services", "vendors", "blogs", "products"];
  if (globalThis.lang !== "da") {
    collections = collections.filter((c) => c !== "blogs");
  }
  const pageData = await Promise.allSettled([
    ...collections.map((c) => {
      const url = `${config.public.cmsUrl}api/collections/${c}/tree?fields=url,api_url,api_id,collection,slug,partner_api_id`;
      // eslint-disable-next-line no-console
      if (process.server) console.log(`Requesting ${url}`);
      return globalThis.$fetch(url);
    }),
  ]);
  return pageData
    .filter((i) => i.status === "fulfilled")
    .flatMap((d: any) => d.value.data);
}

function generateName(page: any) {
  // name for resources are generated like this, to make it easier to link to things with just their slug
  if (page.collection.handle === "pages") return page.url;
  return linkName(page.collection.handle, page.slug);
}
