<template>
  <div
    class="relative grid aspect-[3/2] cursor-pointer gap-2 overflow-hidden rounded-lg md:aspect-[17/5]"
    :class="layoutContainerClasses"
  >
    <ImageLightbox
      :images="images"
      :showing-index="showingIndex"
      :showing-overview="showingOverview"
      :alt-prefix="vendorName"
      @show-index="showingIndex = $event"
      @show-overview="showingOverview = $event"
    />
    <BaseButton
      class="absolute bottom-4 right-4 border-0 bg-white !text-grey-700 opacity-95"
      @click="showingOverview = true"
    >
      {{ $t("vendor_page.view_all_images") }} ({{ images.length }})
    </BaseButton>

    <SingleVendorVideo
      v-if="videoReadyToStream"
      :vendor="vendor"
      :class="layoutFirstImageClasses"
    />
    <SingleVendorImagesTile
      v-if="!videoReadyToStream"
      itemprop="image"
      :image="getImage(0)"
      :action="() => showImageIndex(0)"
      :alt-prefix="vendorName"
      :class="layoutFirstImageClasses"
    />

    <SingleVendorImagesTile
      v-if="layout >= 2"
      itemprop="image"
      :image="getImage(1)"
      :action="() => showImageIndex(1)"
      :alt-prefix="vendorName"
    />

    <SingleVendorImagesTile
      v-if="layout >= 3"
      itemprop="image"
      :image="getImage(2)"
      :action="() => showImageIndex(2)"
      :alt-prefix="vendorName"
    />

    <SingleVendorImagesTile
      v-if="layout === 5"
      itemprop="image"
      :image="getImage(3)"
      :action="() => showImageIndex(3)"
      :alt-prefix="vendorName"
    />

    <SingleVendorImagesTile
      v-if="layout === 5"
      itemprop="image"
      :image="getImage(4)"
      :action="() => showImageIndex(4)"
      :alt-prefix="vendorName"
    />
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from "@vueuse/core";
import SingleVendorImagesTile from "~/pages/components/vendor/single-vendor-images-tile.vue";
import SingleVendorVideo from "~/pages/components/vendor/single-vendor-video.vue";

const props = defineProps<{
  images: Array<{ description: string; url: string }>;
  vendorName: string;
  vendor: OgApi.VendorFull;
}>();

const videoReadyToStream = computed(
  () => !!props.vendor.video?.info?.ready_to_stream,
);
const showingIndex = ref<number | null>(null);
const showingOverview = ref<boolean>(false);

const resizeDebounce = useDebounceFn(() => {
  if (window.innerWidth >= 960) {
    layoutMaxSize.value = 5;
  } else if (window.innerWidth >= 575) {
    layoutMaxSize.value = 3;
  } else {
    layoutMaxSize.value = 1;
  }
}, 300);

onMounted(() => {
  window.addEventListener("resize", resizeDebounce);
});

onUnmounted(() => {
  window.removeEventListener("resize", resizeDebounce);
});

const layoutMaxSize = ref(5);

const layout = computed(() => {
  const videoCount = videoReadyToStream.value ? 1 : 0;
  if (props.images.length + videoCount >= 5 && layoutMaxSize.value === 5) {
    return 5;
  }
  if (props.images.length + videoCount >= 3 && layoutMaxSize.value >= 3) {
    return 3;
  }
  if (props.images.length + videoCount === 2 && layoutMaxSize.value >= 3) {
    return 2;
  }
  return 1;
});

const layoutContainerClasses = computed(() => {
  if (layout.value === 5) {
    return "grid-cols-4 grid-rows-2";
  }
  if (layout.value === 3) {
    return "grid-cols-2 grid-rows-2";
  }
  if (layout.value === 2) {
    return "grid-cols-2 grid-rows-1";
  }
  return "grid-cols-1 grid-rows-1";
});

const layoutFirstImageClasses = computed(() => {
  if (layout.value === 5) {
    return "col-span-2 row-span-2";
  }
  if (layout.value === 3) {
    return "col-span-1 row-span-2";
  }
  return "col-span-1 row-span-1";
});

function getImage(index: number): {
  url: string;
  description: string;
} {
  if (videoReadyToStream.value) {
    return props.images[index - 1];
  }
  return props.images[index];
}

function showImageIndex(index: number) {
  showingIndex.value = videoReadyToStream.value ? index - 1 : index;
}
</script>
