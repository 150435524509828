import { storeToRefs } from "pinia";
import { zarazTracking } from "~/utils/tracking/tracking-zaraz";
import { useAuthStore } from "~/store/AuthStore";
/* This ensures that the TrackingEventType is passed as an argument to TrackingData */
function track<T extends TrackingEventType>(data: TrackingData<T>) {
  const { isLoggedIn } = storeToRefs(useAuthStore());
  if (
    globalThis.document?.cookie?.includes("zaraz-consent") &&
    useRuntimeConfig().public.appEnv === "production"
  ) {
    zarazTracking(data.event, {
      ...data.metadata,
      isLoggedIn: isLoggedIn.value,
    });
  } else if (useRuntimeConfig().public.appEnv !== "production") {
    // eslint-disable-next-line no-console
    console.log("Tracking data:", data);
  }

  if (globalThis.POSTHOG_ENABLED && globalThis.posthog && data.posthogEvent) {
    globalThis.posthog.capture(
      data.posthogEvent.name,
      data.posthogEvent.properties,
    );
  }
}

export { track };
